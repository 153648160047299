import React from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import { Container, ContentWithPaddingXl } from "components/misc/Layouts"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import Header from "components/headers/light.js"
import Footer from "components/footers/MiniCenteredFooter"
import { SectionHeading } from "components/misc/Headings"

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-gray-900 mb-10`
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`

export default ({ headingText = "Parent's Terms And Conditions" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <meta name="robots" content="noindex, nofollow" />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: September 30, 2024</p>

            <h2>
              <strong>Parent's Terms And Conditions</strong>
            </h2>
            <p>
              THIS TERMS AND CONDITION specifies a contract between <strong>CureAble </strong>
              Company (hereinafter referred to as "<strong>Provider</strong>") and the parent
              (hereinafter referred to as the "<strong>Parent</strong>"), collectively referred to
              as the "<strong>Parties</strong>," on the invoice generation date which Parent accepts
              unequivocally.
            </p>
            <p>
              <strong>WHEREAS</strong>, the Provider is engaged in the business of providing home
              healthcare services to special-needs, slow learners or differently abled children; and
            </p>
            <p>
              <strong>WHEREAS</strong>, the Parent is the legal guardian of a special-needs or
              differently abled child and seeks to engage the services of the Provider to render
              home healthcare services to the child.
            </p>
            <p>
              <strong>NOW, THEREFORE</strong>, in consideration of the premises and the mutual
              covenants contained herein, the Parties agree as follows:
            </p>
            <h3>
              <strong>1. Services Provided:</strong>
            </h3>
            <p>
              The Provider agrees to provide home healthcare services to the special-needs or
              differently abled child of the Parent.
            </p>
            <p>
              <strong>1.1 Dedicated Special Educator:&nbsp;</strong>
            </p>
            <p>
              The Provider shall assign a dedicated Special Educator who will provide assistance and
              support to the Child in activities of personal and professional living via short-term
              and long-term goals.
            </p>
            <p>
              <strong>1.2 Dedicated day-by-day planner:</strong>
            </p>
            <p>
              The Provider shall design a dedicated day-to-day planner that will assist in
              organizing and planning the Child's daily activities, including therapy sessions,
              appointments, and recreational activities.
            </p>
            <p>
              <strong>1.3 Dedicated therapist:</strong>
            </p>
            <p>
              The Provider shall assign a dedicated, professionally qualified, and experienced
              therapist who will provide therapeutic services tailored to the Child's specific
              needs, such as physical therapy, occupational therapy, speech therapy, or any other
              therapeutic interventions required.
            </p>
            <p>
              <strong>1.4 Parent support:</strong>
            </p>
            <p>
              The Provider shall provide support to the Parent, including telephonic or online
              consultations, guidance, and assistance in managing the Child's care and addressing
              any concerns or emergencies that may arise, during official office hours of 10AM-6PM.
            </p>
            <p>
              <strong>1.5 Bi-weekly progress report:</strong>
            </p>
            <p>
              The Provider shall prepare and deliver a bi-weekly progress report (every other
              Sunday) to the Parent, detailing the Child's achievements, challenges, and
              recommendations for ongoing care.
            </p>
            <h3>
              <strong>2. Certified and trained Special Educators:</strong>
            </h3>
            <p>
              The provider shall administer Highly qualified and trained Special Educators who have
              prior experience in dealing with special-needs or differently-abled children.&nbsp;
            </p>
            <p>
              <strong>2.1 Special training:</strong>
            </p>
            <p>
              The Special Educator will be additionally trained by the provider, in accordance with
              the child&rsquo;s needs specifically, upon the parent's request.&nbsp;
            </p>
            <h3>
              <strong>3. Schedule and Availability:</strong>
            </h3>
            <p>
              The provider shall make sure that the assigned Special Educator arrives on or before
              the time of appointment. The schedule and routine for the same will be discussed
              during consultation.&nbsp;
            </p>
            <p>
              <strong>3.1 Unavailability of Special Educator:</strong>
            </p>
            <p>
              If an assigned Special Educator is unable to fulfill their duties, due to any personal
              reasons or emergency, the Provider will assign a replacement Special Educator who has
              been similarly trained by CureAble. The provider will inform the parent within 18
              hours of making such a replacement.&nbsp;
            </p>
            <h3>
              <strong>4. Care Plan and Medical Instructions:</strong>
            </h3>
            <p>
              The Provider and the Parent shall mutually agree upon a Service Plan that outlines the
              specific services to be provided, including but not limited to the scope of care,
              frequency of visits, hours of service, and any other relevant details.&nbsp;
            </p>
            <h3>
              <strong>5. Payment and Billing:</strong>
            </h3>
            <p>
              The Parent agrees to pay the Provider the full amount as mentioned in the invoice
              generated for the services rendered in accordance with the following schedule:
            </p>
            <p>
              <strong>5.1 Upfront payment:&nbsp;</strong>
            </p>
            <p>
              The Parent shall pay 50% of the total service fees upon signing these terms and
              conditions.
            </p>
            <p>
              <strong>5.2 Payment after service starts:</strong>
            </p>
            <p>
              The remaining 50% of the total service fees shall be paid by the Parent after the
              service starts.
            </p>
            <p>
              <strong>5.3 Billing and Invoicing:</strong>
            </p>
            <p>
              The Provider shall provide an itemized invoice to the Parent for all services
              rendered, including the applicable fees and any additional charges, if applicable. The
              invoices shall be provided on a monthly basis.
            </p>
            <p>
              <strong>5.4 Payment Methods:</strong>
            </p>
            <p>
              The Parent agrees to make payment via Cash deposit/ UPI / Bank transfer, as agreed
              upon by both Parties. Accepted payment methods may include bank transfers, credit
              cards, or any other mutually agreed-upon method.
            </p>
            <p>
              <strong>5.5 Refund Policy:</strong>
            </p>
            <p>
              CureAble has a &ldquo;<strong>No refund policy</strong>" for their services based on
              several key factors. It recognizes that significant improvements in children's
              conditions typically require at least one month, and issuing refunds within this
              initial period may not accurately reflect the service's effectiveness. Also, a
              dedicated team of therapists, special educators, and consultation experts invests
              considerable time and effort in crafting personalized plans for each child, making it
              challenging to recoup these resources if a refund is granted. The termination of
              services can only be initiated from the next payment date which means services will
              remain effective till the end of the ongoing service month.
            </p>
            <p>
              <strong>5.6 Late Payment Penalty:</strong>
            </p>
            <p>
              In the event that the Parent fails to make a payment within{" "}
              <strong>three (3) days</strong> of the due date, the Provider reserves the right to
              suspend the provision of services until full payment is received. The suspension of
              services does not absolve the Parent of any outstanding payment obligations, and
              services will only resume once the payment is cleared in full.
            </p>
            <p>
              Additionally, continued failure to settle the outstanding payment may result in
              termination of the services, subject to the terms and conditions outlined herein.
            </p>
            <h3>
              <strong>6. Feedback and Communication:</strong>
            </h3>
            <p>
              The parents may feel free to communicate at any time to address any concerns relating
              to their child or to provide feedback or any other reason whatsoever.&nbsp;
            </p>
            <p>
              <strong>6.1 Preferred Mode of Communication:</strong>
            </p>
            <p>
              The Parties agree to establish a dedicated Whatsapp group for communication purposes.
              This group shall serve as the primary mode of communication between the Parent and the
              Provider, allowing for a quick and efficient exchange of information, updates, and
              concerns.
            </p>
            <p>
              <strong>6.2 Bi-Weekly Progress Updates:</strong>
            </p>
            <p>
              The Provider shall provide bi-weekly progress (Every other Sunday) updates to the
              Parent through the designated Whatsapp group. These updates shall include details of
              the Child's achievements, challenges, and any other relevant information regarding the
              care provided during that week.
            </p>
            <p>
              <strong>6.3 Monthly Therapist Progress Session:</strong>
            </p>
            <p>
              The Provider shall schedule a monthly (4th Sunday) therapist progress session with the
              Parent. During this session, the therapist assigned to the Child shall provide a
              comprehensive overview of the Child's progress, address any concerns, and collaborate
              with the Parent to plan further interventions or modifications to the care plan, if
              necessary.
            </p>
            <h3>
              <strong>7. Parents&rsquo; Obligations:&nbsp;</strong>
            </h3>
            <p>
              7.1 The parent shall not, in any case, ask the Special Educator to contract with them
              personally, it is of utmost importance that cureAble must be treated as a middleman
              between the two, with dignity and integrity.&nbsp;
            </p>
            <p>
              7.2 The parent shall keep in check their personal belongings like money, jewellery,
              etc. In case of any unforeseen theft, cureAble will not be responsible for the
              same.&nbsp;
            </p>
            <p>
              7.3 The parent shall be responsible for purchasing any equipment and tools required as
              per the planner for the child, on a needed basis.
            </p>
            <p>
              7.4 Parents are required to provide a minimum of 7 days for the special educator to
              observe the child and establish a rapport with the child, aiming to achieve optimal
              outcomes.
            </p>
            <p>
              <strong>7.5 </strong>The Parent shall treat all Special Educators, therapists, and
              staff with respect and dignity. Any form of verbal or physical harassment, disrespect,
              or inappropriate behaviour toward the Provider&rsquo;s personnel may lead to immediate
              termination of services, without refund, at the sole discretion of CureAble.
            </p>
            <h3>
              <strong>8. Parental Leave Allowance:</strong>
            </h3>
            <p>
              <strong>8.1 Parental Leave Allowance</strong>: Cureable shall permit the Parent to
              take a maximum of three (3) days of leave per calendar month from the services
              provided by Cureable ("Leave Allowance").
            </p>
            <p>
              <strong>8.2 Reimbursement of Leave Allowance</strong>: In the event the Parent
              utilizes any portion of the Leave Allowance, Cureable shall extend the service end
              date for the Parent by the equivalent number of days utilized as part of the Leave
              Allowance.
            </p>
            <p>
              <strong>8.3 Excess Leave</strong>: Any parental leave taken by the Parent exceeding
              the Leave Allowance of three (3) days in any calendar month shall not be covered under
              this service extension policy. Cureable shall not be obliged to extend the service end
              date for the Parent for such excess leave. However, in case of exceptional
              circumstances, as mentioned by the Parent, they will be granted more than 3 days of
              leave in December.
            </p>
            <h3>
              <strong>9. Confidentiality:</strong>
            </h3>
            <p>
              <strong>9.1 From Provider:&nbsp;</strong>
            </p>
            <p>
              The Provider acknowledges that it may have access to confidential information
              concerning the Child and the Parent's family. The Provider agrees to maintain
              the&nbsp;
            </p>
            <p>
              confidentiality of such information and shall not disclose it to any third party
              without the express written consent of the Parent, except as required by law. However,
              the parent understands that the provider may use the therapy session&rsquo;s videos
              and images for marketing and training purposes.
            </p>
            <p>
              <strong>9.2 From parent:</strong>
            </p>
            <p>
              During the term of these terms and conditions and thereafter, the Parent shall
              maintain the confidentiality of all proprietary and confidential information disclosed
              by CureAble. The&nbsp;
            </p>
            <p>
              Parent shall not disclose, use, or exploit such information for any purpose other than
              the performance of its obligations under these terms and conditions unless expressly
              authorized in writing by CureAble.
            </p>
            <p>
              <strong>9.3 Each Party agrees to:</strong>
            </p>
            <ul>
              <li>
                Use the Confidential Information solely for the purposes related to this Agreement;
              </li>
              <li>
                Take all reasonable steps to protect the confidentiality of the information and
                prevent unauthorized access or disclosure;
              </li>
              <li>
                Ensure that Confidential Information is disclosed only to employees or
                representatives who have a need to know and who are bound by confidentiality
                obligations no less restrictive than those in this Agreement.
              </li>
            </ul>
            <p>
              9.4 <strong>Exclusions:</strong>
              <strong>
                <br />
              </strong>
              Confidential Information does not include information that:
            </p>
            <ul>
              <li>
                Is or becomes public knowledge through no breach of this Agreement by the receiving
                Party;
              </li>
              <li>
                Was in the possession of the receiving Party before disclosure by the disclosing
                Party;
              </li>
              <li>
                Is independently developed by the receiving Party without reference to the
                Confidential Information; or
              </li>
              <li>
                Is disclosed by a third party with the legal right to disclose such
                information.&nbsp;
              </li>
            </ul>
            <p>
              9.5 <strong>Disclosure Required by Law:</strong>
              <strong>
                <br />
              </strong>
              If the receiving Party is required by law or regulation to disclose Confidential
              Information, it shall promptly notify the disclosing Party and allow the disclosing
              Party to seek a protective order or take other appropriate measures.
            </p>
            <p>
              <strong>Duration of Confidentiality Obligation:</strong>
              <strong>
                <br />
              </strong>
              The confidentiality obligations shall remain in effect during the term of this
              Agreement and for 5 years, following the termination or expiration of this Agreement.
            </p>
            <h3>
              <strong>10. Emergency Protocols:</strong>
            </h3>
            <p>
              The Provider shall establish and implement comprehensive emergency response procedures
              to address medical emergencies or other urgent situations that may arise during the
              provision of home healthcare services. The emergency protocols shall include:
            </p>
            <p>
              <strong>10.1 Communication Protocols:</strong>
            </p>
            <p>
              The Provider shall establish clear communication channels and procedures to enable
              timely communication between the Provider, the Parent, and emergency medical services,
              if necessary. The designated point of contact and contact information shall be
              provided to the Parent for emergency purposes.
            </p>
            <p>
              <strong>10.2 Steps to be Taken:</strong>
            </p>
            <p>
              The Provider shall develop a guide outlining the necessary actions to be taken in the
              event of a medical emergency or urgent situation. This guide shall include
              instructions for assessing the situation, contacting appropriate medical
              professionals, providing immediate care or first aid, and ensuring the safety and
              well-being of the Child until further medical assistance arrives.
            </p>
            <p>
              <strong>10.3 Parental Responsibility in Emergencies:</strong>
            </p>
            <p>
              The Parent shall take all reasonable measures to ensure the Child&rsquo;s safety in
              case of medical emergencies or urgent situations and shall promptly inform the
              Provider about the situation. The Provider will not be held liable for any delay in
              communication or response if the Parent fails to initiate contact during an emergency.
            </p>
            <h3>
              <strong>11. Compliance with Laws:</strong>
            </h3>
            <p>
              The Provider shall comply with all applicable laws, and ethical guidelines related to
              home healthcare services, ensuring the privacy, dignity, and rights of the parent are
              respected and protected at all times.
            </p>
            <h3>
              <strong>12. Term:</strong>
            </h3>
            <p>
              These terms and conditions shall commence on the Effective Date and shall continue
              until terminated by either Party in accordance with the termination provisions set
              forth herein.
            </p>
            <h3>
              <strong>13. Termination:</strong>
            </h3>
            <p>
              Either Party may terminate these terms and conditions effective from the next service
              month, by providing written notice to the other Party before the intended termination
              date. However, it is to be noted that the termination shall not relieve the Parent of
              any outstanding payment obligations for services provided before the termination date.
            </p>
            <h3>
              <strong>14. Liability and Indemnification:</strong>
            </h3>
            <p>
              14.1 The Provider shall carry appropriate liability insurance coverage to protect
              against claims arising from the provision of home healthcare services. The Parent
              agrees to indemnify and hold the Provider harmless from any claims, damages, or
              liabilities arising out of the acts or omissions of the Child, except to the extent
              caused by the Provider's negligence or willful misconduct.&nbsp;
            </p>
            <p>
              14.2 Any negligence or willful misconduct by the Special Educator will be taken into
              consideration by CureAble as soon as it comes to their notice, and appropriate actions
              will be taken against the breach or misconduct.&nbsp;
            </p>
            <h3>
              <strong>15. Governing Law and Jurisdiction:</strong>
            </h3>
            <p>
              This agreement shall be governed by and construed in accordance with the laws of
              India. Any disputes that may arise under or in connection with the terms and
              conditions shall be subject to the{" "}
              <strong>exclusive jurisdiction of the courts of Delhi</strong>, to the exclusion of
              any other courts situated elsewhere.
            </p>
            <h3>
              <strong>16. Dispute Resolution:</strong>
            </h3>
            <p>
              In the event of any dispute, controversy, or claim arising out of or in connection
              with these terms and conditions, the Parties agree to attempt to resolve the matter
              amicably by discussing the issue in good faith within thirty (15) days of the dispute
              arising. The Parent agrees not to pursue any external legal remedies or initiate legal
              proceedings without first contacting the Provider and making reasonable attempts to
              resolve the matter internally. If the dispute remains unresolved after such
              discussions, the matter shall be referred to the legal head of CureAble for
              resolution.&nbsp;
            </p>
            <p>
              If, after review by the legal head, the dispute still cannot be resolved to the
              satisfaction of the Provider or the parent within 15 business days, either Party may
              then proceed to initiate legal action or pursue litigation in the courts of competent
              jurisdiction in Delhi.
            </p>
            <h3>
              <strong>17. Entire terms and conditions:</strong>
            </h3>
            <p>
              These terms and conditions constitute the entire terms and conditions between the
              Parties concerning the subject matter hereof and supersede all prior negotiations,
              understandings, and terms and conditions, whether oral or written.
            </p>
            <p>
              The Parties hereto have agreed to these terms and conditions as of the Effective Date.
            </p>
            <p>
              <strong>CureAble</strong>
            </p>
            <p>
              <strong>By CUREABLE HOME HEALTH CARE PVT. LTD.</strong>
            </p>
            <h2>
              <br />
              <br />
            </h2>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  )
}
